<template>
  <div class="container-login text-center">
    <div class="container-white">
      <div class="text-right q-mr-sm q-mt-sm">
        <LanguageSwitcher />
      </div>
      <div>
        <img class="q-ma-lg img-register" :src="settings.logo ? settings.logo : defaultImageApp" />
        <h4 class="q-ma-sm q-mb-lg">{{ $t("Login.register") }}</h4>
      </div>
      <div class="items-center q-pa-lg justify-center">
        <q-form class="q-gutter-sm" @submit.prevent="handleRegister">
          <!-- Name -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.name") }}
          </div>
          <q-input
            class="input q-ml-xl q-mr-xl"
            rounded
            outlined
            v-model="userForm.name"
            :placeholder="$t('Login.SignUp.your-name')"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 2) || $t('Login.introduce-name'),
            ]"
          />
          <!-- Surname -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.surname") }}
          </div>
          <q-input
            class="input q-ml-xl q-mr-xl"
            rounded
            outlined
            v-model="userForm.last_name"
            :placeholder="$t('Login.SignUp.your-surname')"
            :dense="dense"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 2) || $t('Login.introduce-surname'),
            ]"
          />
          <!-- Mail -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.mail") }}
          </div>
          <q-input
            class="input q-ml-xl q-mr-xl"
            rounded
            outlined
            v-model="userForm.email"
            :placeholder="$t('Login.your-mail')"
            :dense="dense"
            lazy-rules
            :rules="[
              (val) =>
                !val ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val) ||
                $t('Login.email-required'),
            ]"
          />
          <!-- Phone -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.phone") }}
          </div>
          <q-input
            class="input q-ml-xl q-mr-xl"
            outlined
            rounded
            v-model="userForm.phone"
            :placeholder="$t('Login.SignUp.your-phone')"
            :dense="dense"
            lazy-rules
            :rules="[
              (val) => (val && val.length >= 9) || $t('Login.SignUp.min-phone'),
            ]"
          />
          <!-- Birthday -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.birthday") }}
          </div>
          <q-input
            class="input q-ml-xl q-mr-xl"
            outlined
            rounded
            dense
            v-model="userForm.birthday"
            placeholder="DD/MM/YYYY"
            mask="##/##/####"
            lazy-rules
            :rules="[
              (val) => (val.length === 0 || val.length === 10) || $t('Login.SignUp.wrong-date'),
            ]"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="userForm.birthday"
                    mask="DD/MM/YYYY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <!-- Password -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.password") }}
          </div>
          <q-input
            rounded
            outlined
            v-model="userForm.password"
            :type="isPwd ? 'password' : 'text'"
            :dense="dense"
            :placeholder="$t('Login.your-password')"
            class="q-ml-xl q-mr-xl"
            lazy-rules
            :rules="[
              (val) =>
                (val && /\d/.test(val) && val.length >= 8) ||
                $t('Login.SignUp.min-password'),
            ]"
            autocomplete="on"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <!-- Repeat password -->
          <div
            class="text-subtitle2 text-left text-weight-bold q-ml-xl q-pa-none"
          >
            {{ $t("Login.repeat-your-password") }}
          </div>
          <q-input
            rounded
            outlined
            v-model="userForm.password_confirmation"
            :type="isPwd2 ? 'password' : 'text'"
            :dense="dense"
            :placeholder="$t('Login.repeat-your-password')"
            class="q-ml-xl q-mr-xl"
            lazy-rules
            :rules="[
              (val) =>
                (val && /\d/.test(val) && val.length >= 8) ||
                $t('Login.SignUp.min-password'),
            ]"
            autocomplete="on"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd2 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd2 = !isPwd2"
              />
            </template>
          </q-input>

          <!-- Checkbox -->
          <div class="justify-center" style="display: flex">
            <q-checkbox
              style="display: flex; margin: 0; padding: 0; font-size: 13px"
              name="accept_agreement"
              v-model="acceptAgreement"
            >
              <a
                class="q-py-md"
                style="cursor: default;"
                @click.stop="(event) => event.stopPropagation()"
              >
                {{ $t('Login.accept-policy-text-part') }}
              </a>
              <a
                class="text-weight-bold q-py-md"
                style="text-decoration: underline;"
                @click.stop="(event) => event.stopPropagation()"
                @click="showPolicyDialog()"
              >
                {{ $t('Login.accept-policy-link-part') }}
              </a>
            </q-checkbox>
          </div>

          <!-- ReCaptcha -->
          <div class="justify-center q-mt-lg" style="display: flex">
            <vue-recaptcha
              v-show="showRecaptcha"
              :sitekey=reCaptchaSiteKey
              size="normal" 
              theme="light"
              :loading-timeout="loadingTimeout"
              @verify="recaptchaVerified"
              @expire="recaptchaExpired"
              @fail="recaptchaFailed"
              @error="recaptchaError"
              ref="vueRecaptchaRef"
            />
          </div>

          <ButtonSignUp type="submit"
            >{{ $t("Login.register") }}
            <q-spinner
              v-if="loading"
              size="20px"
              style="position: absolute; right: 15px"
            />
          </ButtonSignUp>
        </q-form>
      </div>
    </div>

    <!-- Dialog usuario creado con exito -->

    <UserCreatedSuccess v-model="successCreated" :settings="settings" />

    <!-- Dialog usuario no creado -->

    <UserCreatedFail v-model="failCreated" :settings="settings" :errorData="failErrorData" />

    <DialogPolicy v-model="policyDialog" :settings="settings" />

    <q-dialog persistent v-model="acceptPolicy">
      <q-card :style="{ 'font-family': settings.typography }" class="q-pb-md">
        <q-card-section>
          <div class="text-h6 text-center">
            {{ $t("Login.please-accept-policy") }}
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            :label="$t('Login.close')"
            v-close-popup
            class="button-policy"
            :style="{ 'background-color': settings.color_primary }"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="">
      <FooterPolitics />

      <p class="q-ma-sm" style="font-size: 12px">
        {{ $t("Login.have-account") }}
        <router-link to="/login"
          ><span class="login-span cursor-pointer">{{
            $t("Login.go-back-login")
          }}</span>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions } from "vuex";
import SignUpMixin from "../mixins/SignUpMixin";
import settings from "@/mixins/settings";
import config from "../../../../public/config.json";
import vueRecaptcha from 'vue3-recaptcha2';

const LanguageSwitcher = defineAsyncComponent(() =>
  import("../../shared/components/LanguageSwitcher.vue")
);

const ButtonSignUp = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonSignUp.vue")
);

const FooterPolitics = defineAsyncComponent(() =>
  import("../components/FooterPolitics.vue")
);

const UserCreatedSuccess = defineAsyncComponent(() =>
  import("../components/UserCreatedSuccess.vue")
);

const UserCreatedFail = defineAsyncComponent(() =>
  import("../components/UserCreatedFail.vue")
);

const DialogPolicy = defineAsyncComponent(() =>
  import("../components/DialogPolicy.vue")
);

export default {
  mixins: [SignUpMixin, settings],
  name: "Register",
  components: {
    ButtonSignUp,
    FooterPolitics,
    UserCreatedSuccess,
    UserCreatedFail,
    DialogPolicy,
    vueRecaptcha,
    LanguageSwitcher,
  },
  data() {
    return {
      successCreated: false,
      failCreated: false,
      failErrorData: {},
      loading: false,
      acceptPolicy: false,
      policyDialog: false,
      acceptAgreement: false,

      // Recaptcha
      showRecaptcha: true,
		  loadingTimeout: 30000, // 30 seconds
      vueRecaptchaRef: null,
      reCaptchaSiteKey: config.reCaptcha.sitekey,
    };
  },
  setup() {
    const userForm = ref({
      email: "",
      password: "",
      password_confirmation: "",
      name: "",
      last_name: "",
      phone: "",
      birthday: "",
      ow_params: {},
      captcha_token: null,
    });

    return {
      userForm,
      isPwd2: ref(true),
      isPwd: ref(true),
      dense: ref(""),
    };
  },
  methods: {
    ...mapActions("auth", ["signIn"]),

    showPolicyDialog() {
      this.policyDialog = true;
    },

    // ReCaptcha
    recaptchaVerified(response) {
      this.userForm.captcha_token = response;
    },
    recaptchaExpired() {
      this.vueRecaptchaRef.reset();
    },
    recaptchaFailed() {

    },
    recaptchaError(reason) {

    }
  },
};
</script>

<style lang="scss" scoped>
.img-register {
  width: 150px;
}

h4 {
  font-weight: 700;
  font-size: 32px;
}

.q-form {
  height: 720px;
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

.login-span {
  color: v-bind("settings.color_primary");
  text-decoration-line: underline;
}

.container-login {
  background: #e5e5e5;
  padding-bottom: 30px;
  .container-white {
    background: white;
    border-bottom-left-radius: 50% 35% !important;
    border-bottom-right-radius: 50% 35% !important;
    /* margin-bottom: 10px; */
    padding-bottom: 13rem;
    border-radius: 0px 0px 36% 36%;
    box-shadow: 0px 8px 16px 0px #00000033;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    margin-left: 25%;
    margin-right: 25%;
    h4 {
      font-size: 28px;
    }
    .items-center {
      padding: 25px 70px;
    }
  }

  @media only screen and (min-width: 767px) {
    .text-subtitle2,
    .q-input {
      padding-left: 13%;
      padding-right: 13%;
    }
  }

  @media only screen and (max-width: 767px) {
    .container-white {
      margin-left: 0%;
      margin-right: 0%;
      border-bottom-left-radius: 50% 15% !important;
      border-bottom-right-radius: 50% 15% !important;
      border-radius: 0px 0px 32% 32%;
      padding-bottom: 15rem;
      .items-center {
        padding: 0px 10px;
      }
    }
    .q-form {
      margin-right: 2%;
      margin-left: 2%;
    }
  }

  @media (min-width: 768px) and (max-width: 820px) {
    .container-white {
      margin-left: 10%;
      margin-right: 10%;
      border-radius: 0px 0px 40% 40%;
      padding-bottom: 13rem;
      .items-center {
        padding: 25px 10px;
      }
    }
    .q-form {
      margin-right: 10%;
      margin-left: 10%;
    }
  }

  @media (min-width: 820px) and (max-width: 1020px) {
    .container-white {
      margin-left: 20%;
      margin-right: 20%;
      border-radius: 0px 0px 40% 40%;
      padding-bottom: 13rem;
      .items-center {
        padding: 25px 10px;
      }
    }
  }
}

.button-policy {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}
</style>
