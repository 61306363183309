<template>

  <q-card class="my-card text-left q-ma-md q-pa-md">
    <q-card-section class="no-border q-pb-none q-pt-none">
      <div style=" display: flex; justify-content: space-between; align-items: center;">
        <p class="q-ma-none">{{ $t("General.balance") }}</p>
          <router-link to="/transacciones">
            <q-card-section class="underline-text title-transacciones q-pa-sm text-weight-bold">
              {{ $t("Home.see-movements") }}
            </q-card-section>
          </router-link>
      </div>
      <div class="text-h4 text-weight-bold">
        {{ points.points.points }}
        {{ settings.currency_abbreviation }}
      </div>
      <p class="q-mt-md q-pa-none">
        {{ $t("ControlPanel.card-number") }}:
        <span class="text-weight-bold">{{ user.card }}</span>
      </p>

      <p class="text-h6 text-weight-bold" style="text-align: center;">
        {{ $t("ControlPanel.download-card") }}
      </p>
      <div class="q-mt-md pass-download-card" style="display: flex; align-items: center; justify-content: center;">
        <div>
          <a :href="user.ios_pass" target="_blank">
            <q-img src="@/assets/images/ios-pass.png" spinner-color="white" class="q-my-sm q-mx-sm" style="width: 135px;" />
          </a>
        </div>
        <div>
          <a :href="user.google_wallet_pass" target="_blank">
            <q-img src="@/assets/images/google-wallet-pass.png" spinner-color="white" class="q-my-sm q-mx-sm" style="width: 155px;" />
          </a>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-card class="my-card text-left q-ma-md q-pb-sm">
    <q-list>
      <q-item >
        <q-item-section>
          <q-item-label class="text-weight-bold">{{ $t("Profile.personal-data.my-data") }}</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon color="primary" class="cursor-pointer icon-white" name="edit" @click="dialog = true" />
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.email")
          }}</q-item-label>
          <q-item-label>{{ user.email }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.name")
          }}</q-item-label>

          <q-item-label>{{ user.name }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.surname")
          }}</q-item-label>
          <q-item-label >{{
            user.last_name
          }}</q-item-label>
          <!-- <q-item-label v-else>Por favor, registre un apellido</q-item-label> -->
        </q-item-section>
      </q-item>

      <!-- <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>Fecha de nacimiento</q-item-label>
           <q-item-label>{{ }}</q-item-label> 
        </q-item-section>
      </q-item> -->

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.phone")
          }}</q-item-label>
          <q-item-label >{{
            user.phone
          }}</q-item-label>
          <!-- <q-item-label v-else>Por favor, registre un teléfono</q-item-label> -->
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>
  <EditPersonalData v-model="dialog" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";
import settings from "@/mixins/settings";

const EditPersonalData = defineAsyncComponent(() =>
  import("../editprofile/EditPersonalData.vue")
);

export default {
  mixins: [settings],
  components: {
    EditPersonalData,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    points() {
      return this.$store.state.points.points;
    },
  },
};
</script>

<style lang="scss" scoped>
.q-card {
    border-radius: 25px 25px 25px 0px;

}
</style>

