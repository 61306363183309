import axios from "../../../services/Api";
export default {
  computed: {
    endingBalanceStore() {
      return this.points.points.points - this.product.value;
    },
    endingBalanceHomeDelivery() {
      if ((this.homeDelivery = true)) {
        return (
          this.points.points.points -
          (this.product.value + this.settings.shipping_price)
        );
      }
    },
    totalBalance() {
      return this.product.value + this.settings.shipping_price;
    },
  },
  methods: {
    // Muestra el Dialog de Recogida en tienda y oculta otras opciones
    showStoreAddress() {
      this.dialogPickUpStoreOptions = true;
      this.optionChosenHomeDelivery = false;
    },

    // Muestra la tienda escogida
    chooseStoreAddress() {
      this.chosenShippingAddress = null;
      this.chosenBillingAddress = null;
      this.dialogPickUpStoreOptions = false;
      this.optionChosenHomeDelivery = false;
      this.optionChosenStore = true;
    },

    // Muestra el Dialog de Envío a Domicilio y oculta otras opciones
    showHomeDeliveryAddress() {
      this.dialogHomeDeliveryOptions = true;
      this.optionChosenStore = false;
    },

    // Muestra la dirección de Envío a Domicilio escogida
    chooseHomeDeliveryAddress() {
      this.chosenShippingAddress = null;
      this.chosenBillingAddress = null;
      this.dialogHomeDeliveryOptions = false;
      this.optionChosenStore = false;
      this.optionChosenHomeDelivery = true;
    },

    // Emit de recogida en tienda
    displayStoreOption(chosenOption) {
      this.chosenOption = chosenOption;
      this.optionChosenStore = true;
    },

    // Emit de envío a domicilio
    displayHomeDeliveryOption(infoProfile) {
      this.shipping_address_aux = infoProfile;
      this.optionChosenHomeDelivery = true;
    },

    // Emit que transforma la Dirección por defecto en la nueva Dirección. El comentario tiene que se mayor que 5.
    displayAddress(addressCopy) {
      this.shipping_address_aux = addressCopy;
    },

    getProductOptions() {
      this.product?.options?.attributes?.forEach((attribute) => {
          this.productAttributes.push({
            label: attribute.label,
            options: attribute.options.filter((option) => option.enabled === true).map((option) => option.label),
            value: null
          });
      });
    },

    setHomeDelivery() {
      // Vitual product
      if (this.product.type === 1) {
        this.homeDelivery === false;
      }
    },

    // Cuando escoges una opción y das click al botón
    chooseOption() {
      let checkoutFormFilled = true;
      let checkoutHomeDelivery = false;
      let checkoutStoreDelivery = false;

      // Check product attribute options
      this.productAttributes.forEach((attribute) => {
        if (attribute.value === null) {
          checkoutFormFilled = false;
          this.alertPleaseChooseOption = true;
        }
      });

      // Check delivery option
      if (this.chosenShippingAddress != null && this.chosenBillingAddress != null) {
        if (this.optionChosenHomeDelivery == true) {
          checkoutHomeDelivery = true;
        } else if (this.optionChosenStore == true) {
          checkoutStoreDelivery = true;
        }
      } else {
        checkoutFormFilled = false;
        this.alertPleaseChooseAddress = true;
      }

      // Proceed checkout confirmation page
      if (checkoutFormFilled) {
        if (checkoutHomeDelivery === true) {
          this.buttonBlueExchange = false;
          this.buttonOrangeExchange = true;
          this.titleHomeDelivery = true;
          this.pickUpStore = false;
          this.homeDelivery = false;
          document.getElementById("buttonBuyProduct").style.zIndex = "1";
        } else if (checkoutStoreDelivery === true || this.product.type === 1) {
          this.buttonBlueExchange = false;
          this.buttonOrangeExchange = true;
          this.titlePickUpStore = true;
          this.pickUpStore = false;
          this.homeDelivery = false;
          document.getElementById("buttonBuyProduct").style.zIndex = "1";
        }
      }
    },

    sendOrder() {
      this.loadingOrder = true;
      setTimeout(() => {
        // Order request body
        const body = new FormData();
        body.append(
          "shipping_address_id",
          this.chosenShippingAddress.id
        )
        body.append(
          "invoice_address_id",
          this.chosenBillingAddress.id
        )
        if (this.addNote) {
          body.append(
            "comments",
            this.NoteInput
          )
        }
        
        // Make a different request depending on if it's a catalog product or a coupon
        let response = null
        if (this.product.isCoupon === true) {
          response = this.createCouponOrder(body);
        } else {
          response = this.createOrder(body);
        }

        response
          .then(() => {
            this.loadingOrder = false;
            this.OrderDone = true;
            document.getElementById("buttonBuyProduct").classList.add("disabled");
          })
          .catch((error) => {
            this.loadingOrder = false;
            this.orderFail = true;
          })
        
      }, 3000);
    },

    async createOrder(body) {
      body.append(
        "order_lines[]",
        JSON.stringify({
          product_id: this.product.id,
          quantity: 1,
          value: this.product.value,
          product_options: {
            attributes: this.productAttributes.map((attribute) => {
              return {
                label: attribute?.label,
                value: attribute?.value?.value
              }
            })
          }
        })
      );
      /*body.append("home_delivery",
        this.homeDelivery == true
          ? 1
          :0
      );*/

      return axios
        .post("front-catalog/orders", body)
    },

    async createCouponOrder(body) {
      return axios
        .post(`front-catalog/chequemotiva/${this.product.couponData.type}`, body)
    },
  },
};
