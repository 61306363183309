export const couponMixin = {
  data() {
    return {
      coupons: [
        {
          id: 1,
          type: 'galp-20',
          thumbnail: 'https://www.shutterstock.com/image-vector/special-offer-20-off-label-600w-1060049819.jpg',
          name: 'Galp - 20% de descuento',
          description: 'Descripción del producto A',
          value: 20.00,
          price: 20.00,
          visible: true,
          category: 'Carburante',
          brand: 'Galp',
        },
        {
          id: 2,
          type: 'decathon-20',
          thumbnail: 'https://cdn.palbincdn.com/users/6661/images/cupon-descuento-untitled-1505631856@x512--f[as].png',
          name: 'Decathlon - 20% de descuento',
          description: 'Descripción del producto B',
          value: 20.00,
          price: 20.00,
          visible: true,
          category: 'Deportes',
          brand: 'Decathlon',
        },
      ]
    };
  },
  methods: {
    getCoupons() {
      return this.coupons;
    }
  }
};
