import axios from "axios";
import config from "../../public/config.json"

const ApiOmniwallet = axios.create({
    withCredentials: true,
    timeout: 30000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`
    }
});

ApiOmniwallet.interceptors.request.use(function (axiosConfig) {

    if (config.api.dev_mode === false) {
        axiosConfig.baseURL = `https://dashboard.${window.location.host.split('.')[1]}.net`; // We use the catalog domain to decide wether to call the production or staging backend
        axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`; // Used for automatic user login in some integrations
        const subdomain = window.location.host.split('.')[0]
        axiosConfig.headers['X-Omniwallet-Account'] = subdomain // The catalog subdomain is used to identify the Omniwallet account
    } else {
        axiosConfig.baseURL = config.api.url;
        axiosConfig.headers['X-Omniwallet-Account'] = config.api.dev_header;
        axiosConfig.headers.Authorization = `Bearer ${config.api.dev_token}`;
    }

    // For local testing (TODO: should check node env prod or dev instead)
    //const hostname = 'tenant.base_omniwallet.test';
    //config.baseURL = `https://${hostname}`;

    return axiosConfig;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default ApiOmniwallet;
